.App {
  text-align: center;
  color: #000000;
}

.infoBox {
  display: 'flex';
  text-align: center;
  width: 100%;
}

.txtHead {
  color: black;
  display: 'flex';
  font-size: 17px;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #a30707;
}